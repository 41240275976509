import {
  CheckboxGroupInput,
  ChipField,
  Create,
  Datagrid,
  DateField,
  Edit,
  ImageField,
  List,
  RadioButtonGroupInput,
  ReferenceArrayInput,
  Resource,
  Show,
  SimpleForm,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TextInput,
  DateTimeInput,
  NumberInput,
  ArrayField,
  BooleanInput
} from 'react-admin';
import { ProgramFilterSidebar } from '../components/FilterSidebar';
import { ImageInput } from '../components/ImageInput';
import { NestedCheckboxGroupInput } from '../components/NestedCheckboxGroupInput';
import gql from 'graphql-tag';
import { WORKOUT_TYPES } from '../services/constants';
import StatusField from '../components/StatusField';
import { VideoInput } from '../components/VideoInput';

export const name = 'Program';
export const fragments = {
  type: 'document',
  doc: gql`
    fragment FragPrograms on programs {
      id
      name
      workoutType
      description
      imageUrl
      order
      isExcludeFromList
      introVideoUrl
      publishedAt
      createdAt
      updatedAt
      _count {
        categories
        instructors
        levels
        schedules
        __typename
      }
      categories {
        id
        name
        __typename
      }
      devices {
        id
        name
        __typename
      }
      equipments {
        id
        name
        __typename
      }
      goals {
        id
        name
        __typename
      }
      injuries {
        id
        name
        __typename
      }
      instructors {
        id
        name
        __typename
      }
      interests {
        id
        name
        __typename
      }
      levels {
        id
        name
        __typename
      }
      muscleGroups {
        id
        name
        __typename
      }
      __typename
    }
  `
};

const list = () => (
  <List aside={<ProgramFilterSidebar />} sort={{ field: 'name', order: 'ASC' }}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="workoutType" />
      <TextField source="description" />
      <ImageField label="Image" source="imageUrl" />
      <VideoInput source="introVideoUrl" display="LIST" />
      <DateField label="Published" source="publishedAt" />
      <TextField source="order" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <ArrayField source="accessories">
        <SingleFieldList resource="Accessory">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="categories">
        <SingleFieldList resource="Category">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="devices">
        <SingleFieldList resource="Device">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="equipments">
        <SingleFieldList resource="Equipment">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="goals">
        <SingleFieldList resource="Goal">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="injuries">
        <SingleFieldList resource="Injury">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="instructors">
        <SingleFieldList resource="Instructor">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="interests">
        <SingleFieldList resource="Interest">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="levels" sort={{ field: 'order', order: 'ASC' }}>
        <SingleFieldList resource="WorkoutLevel">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField label="Muscle Groups" source="muscleGroups">
        <SingleFieldList resource="MuscleGroup">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
    </Datagrid>
  </List>
);

const show = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="workoutType" />
      <TextField source="description" />
      <ImageField label="Image" source="imageUrl" />
      <VideoInput
        label="Intro Video"
        title="introVideoUrl"
        source="introVideoUrl"
      />
      <DateField label="Published" source="publishedAt" />
      <TextField source="order" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />

      <ArrayField source="accessories">
        <SingleFieldList resource="Accessory">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="categories">
        <SingleFieldList resource="Category">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="devices">
        <SingleFieldList resource="Device">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="equipments">
        <SingleFieldList resource="Equipment">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="goals">
        <SingleFieldList resource="Goal">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="injuries">
        <SingleFieldList resource="Injury">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="instructors">
        <SingleFieldList resource="Instructor">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="interests">
        <SingleFieldList resource="Interest">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="levels" sort={{ field: 'order', order: 'ASC' }}>
        <SingleFieldList resource="WorkoutLevel">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField label="Muscle Groups" source="muscleGroups">
        <SingleFieldList resource="MuscleGroup">
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <TextField source="name" />
      <StatusField
        fieldName={'isExcludeFromList'}
        falseText={'Not Excluded'}
        TrueText={'Excluded'}
      />
    </SimpleShowLayout>
  </Show>
);

const edit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm>
      <TextField source="id" />
      <TextInput source="name" />
      <RadioButtonGroupInput
        source="workoutType"
        choices={[
          { id: WORKOUT_TYPES.STUDIO.value, name: WORKOUT_TYPES.STUDIO.label },
          {
            id: WORKOUT_TYPES.OPEN_GYM.value,
            name: WORKOUT_TYPES.OPEN_GYM.label
          }
        ]}
      />
      <TextInput source="description" multiline rows={4} fullWidth />
      <ImageInput label="Image" source="imageUrl" />
      <VideoInput source="introVideoUrl" display="EDIT" />
      <DateTimeInput source="publishedAt" />
      <NumberInput source="order" />
      <ReferenceArrayInput source="accessories_ids" reference="Accessory">
        <CheckboxGroupInput optionValue="id" optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        source="categories_ids"
        reference="Category"
        perPage={1000}
      >
        <NestedCheckboxGroupInput
          optionValue="id"
          optionParentValue="parentId"
          optionText="name"
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="devices_ids" reference="Device">
        <CheckboxGroupInput optionValue="id" optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="equipments_ids" reference="Equipment">
        <CheckboxGroupInput optionValue="id" optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="goals_ids" reference="Goal">
        <CheckboxGroupInput optionValue="id" optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="injuries_ids" reference="Injury">
        <CheckboxGroupInput optionValue="id" optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="instructors_ids" reference="Instructor">
        <CheckboxGroupInput optionValue="id" optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="interests_ids" reference="Interest">
        <CheckboxGroupInput optionValue="id" optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        source="levels_ids"
        reference="WorkoutLevel"
        sort={{ field: 'order', order: 'ASC' }}
      >
        <CheckboxGroupInput optionValue="id" optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="muscleGroups_ids" reference="MuscleGroup">
        <CheckboxGroupInput optionValue="id" optionText="name" />
      </ReferenceArrayInput>
      <BooleanInput label="Exclude From List" source="isExcludeFromList" />
    </SimpleForm>
  </Edit>
);

const create = () => (
  <Create>
    <SimpleForm>
      <TextField source="id" />
      <TextInput source="name" />
      <RadioButtonGroupInput
        source="workoutType"
        choices={[
          { id: WORKOUT_TYPES.STUDIO.value, name: WORKOUT_TYPES.STUDIO.label },
          {
            id: WORKOUT_TYPES.OPEN_GYM.value,
            name: WORKOUT_TYPES.OPEN_GYM.label
          }
        ]}
      />
      <TextInput source="description" multiline rows={4} fullWidth />
      <ImageInput label="Image" source="imageUrl" create />
      <VideoInput source="introVideoUrl" accept="video/*" display="CREATE" />
      <DateTimeInput source="publishedAt" />
      <NumberInput source="order" />
      <ReferenceArrayInput source="accessories_ids" reference="Accessory">
        <CheckboxGroupInput
          optionValue="id"
          optionText="name"
          defaultValue={[]}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        source="categories_ids"
        reference="Category"
        perPage={1000}
      >
        <NestedCheckboxGroupInput
          row={true}
          optionValue="id"
          optionParentValue="parentId"
          optionText="name"
          defaultValue={[]}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="devices_ids" reference="Device">
        <CheckboxGroupInput
          optionValue="id"
          optionText="name"
          defaultValue={[]}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="equipments_ids" reference="Equipment">
        <CheckboxGroupInput
          optionValue="id"
          optionText="name"
          defaultValue={[]}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="goals_ids" reference="Goal">
        <CheckboxGroupInput
          optionValue="id"
          optionText="name"
          defaultValue={[]}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="injuries_ids" reference="Injury">
        <CheckboxGroupInput
          optionValue="id"
          optionText="name"
          defaultValue={[]}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="instructors_ids" reference="Instructor">
        <CheckboxGroupInput
          optionValue="id"
          optionText="name"
          defaultValue={[]}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="interests_ids" reference="Interest">
        <CheckboxGroupInput
          optionValue="id"
          optionText="name"
          defaultValue={[]}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        source="levels_ids"
        reference="WorkoutLevel"
        sort={{ field: 'order', order: 'ASC' }}
      >
        <CheckboxGroupInput
          optionValue="id"
          optionText="name"
          defaultValue={[]}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="muscleGroups_ids" reference="MuscleGroup">
        <CheckboxGroupInput
          optionValue="id"
          optionText="name"
          defaultValue={[]}
        />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);

export default function AdminResource(props) {
  return (
    <Resource
      create={create}
      edit={edit}
      list={list}
      name={name}
      show={show}
      {...props}
    />
  );
}
